<template>
  <k-field-group language-prefix="employer.fields">
    <k-sidebar
      v-bind="$attrs"
      :save-button-text="$t('actions.applyFilters')"
      :title="$t('employer.filterTitle')"
      fixed
      right
      temporary
      width="400"
      :cancel-button-text="$t('actions.cleanFilters')"
      :with-cancel-button="true"
      v-on="$listeners"
      @click:confirm="handleFilterClick"
      @click:cancel="handleCleanFilter"
    >
      <k-select
        v-model="form.employerTypeId"
        v-bind="$attrs"
        :items="employerStatusOptions"
        clearable
        field="employerStatus"
      />
    </k-sidebar>
  </k-field-group>
</template>

<script>
import employerStatuses from '@/application/enums/employerStatus.json';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KSidebar from '@/components/layout/KSidebar';
import { cleanUpFilters } from '@/application/helpers/cleanUpFilters';

export default {
  name: 'EmployerFilter',
  components: {
    KSelect,
    KSidebar,
    KFieldGroup,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object,
    },
  },
  data: () => ({
    form: {
      employerTypeId: 0,
    },
  }),
  computed: {
    employerStatusOptions() {
      return Object.values(employerStatuses).map(key => {
        return {
          value: key,
          text: this.$t(`employer.fields.employerStatusEnums.${key}`),
        };
      });
    },
  },
  watch: {
    filters: {
      handler(values) {
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      this.$emit('update:filters', cleanUpFilters(this.form));
      this.$emit('input', false);
    },
    handleCleanFilter(){
      this.form = {};
      this.$emit('update:filters', this.form);
    },
  },
};
</script>
