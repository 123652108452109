<template>
  <div>
    <KCrudLayout
      :filters.sync="filters"
      :search.sync="searchQuery"
    >
      <template #header>
        {{ $tc('employer.title', 2) }}
      </template>
      <template #filters="{ attrs, on }">
        <EmployerFilter
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('employer.title', 1) }) }}
        </v-btn>
        <EmployerForm
          v-model="createDialog"
          :request="createRequest"
          :title="$tc('employer.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="index"
          :search="searchQuery"
          :params="filters"
          language-prefix="employer.fields"
          resource="employer"
          show-expand
          single-expand
          :can-delete="canDelete"
          @click:edit="openUpdate"
          @click:row="$router.push({ name: 'employer.show', params: { employerId: $event.id } })"
          @click:delete="deleteEmployer"
        >
          <template #item.employerTypeId="{ item }">
            {{ $t(`employer.fields.employerStatusEnums.${item.employerTypeId}`) }}
          </template>
          <template #expanded-item="{ item }">
            <v-row class="ma-0">
              <v-col cols="2">
                <VImg
                  :lazy-src="`${item.logoUrl}&type=lazy`"
                  :src="`${item.logoUrl}&type=small`"
                  alt="logo"
                  class="mt-2"
                  width="100%"
                />
              </v-col>
              <v-col cols="3">
                <div class="text-h6">
                  {{ item.name }}
                </div>
                <div class="subtitle-1">
                  {{ item.city }}
                </div>
                <div class="body-1 mt-12">
                  {{ item.about }}
                </div>
              </v-col>
              <v-col cols="3 body-1">
                <v-row class="ma-3 my-5">
                  <v-icon class="float-left">
                    $phone
                  </v-icon>
                  <a
                    :href="`tel:${item.phone}`"
                    class="float-left ml-3"
                    target="_blank"
                  >{{ item.phone }}</a>
                </v-row>
                <v-row class="ma-3 my-5">
                  <v-icon class="float-left">
                    $fax
                  </v-icon>
                  <a
                    :href="`fax:${item.fax}`"
                    class="float-left ml-3"
                    target="_blank"
                  >{{ item.fax }}</a>
                </v-row>
                <v-row class="ma-3 my-5">
                  <v-icon class="float-left">
                    $email
                  </v-icon>
                  <a
                    :href="`mailto:${item.email}`"
                    class="float-left ml-3"
                    target="_blank"
                  >{{ item.email }}</a>
                </v-row>
                <v-row class="ma-3 my-5">
                  <v-icon class="float-left">
                    $website
                  </v-icon>
                  <a
                    :href="item.website"
                    class="float-left ml-3"
                    target="_blank"
                  >{{ item.website }}</a>
                </v-row>
              </v-col>
            </v-row>
          </template>
        </k-crud-table>
      </template>
    </KCrudLayout>
    <EmployerForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('employer.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import Employer from '@/application/models/Employer.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update, remove } from '@/modules/employer/api/employer.js';
import EmployerFilter from '@/modules/employer/components/EmployerFilter.vue';
import EmployerForm from '@/modules/employer/components/EmployerForm.vue';

export default {
  name: 'EmployerIndex',
  components: {
    EmployerForm,
    EmployerFilter,
    KCrudTable,
    KCrudLayout,
  },
  mixins: [querySyncMixin],
  data() {
    return {
      filters: {},
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Employer(),
      createFormValues: new Employer(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'city',
          language: 'address.city',
        },
        {
          value: 'phone',
          language: 'contactDetail.phone',
        },
        {
          value: 'email',
          language: 'contactDetail.email',
        },
        {
          value: 'employerTypeId',
          language: 'employerStatus',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
    ]);
  },
  methods: {
    index,
    handleOpenCreateDialog() {
      this.createFormValues = new Employer();
      this.createDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.createFormValues);
    },
    async openUpdate(item) {
      this.updateFormValues = new Employer();
      const employerShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(employerShowRequest.data.data);
      this.updateDialog = true;
    },
    canDelete(item) {
      return item.isDeletable;
    },
    async deleteEmployer(employer) {
      eventBus.$emit('confirm', {
        title: this.$t('actions.deleteConfirmation.title'),
        body: this.$t('actions.deleteConfirmation.bodyAlt', {
          resource: this.$tc('employer.title', 1).toLowerCase(),
        }),
        confirmCallback: async () => {
          try {
            await remove(employer.id);
            this.$refs.table.reload();
            eventBus.$emit('snackbar', {
              color: 'success',
              text: this.$t('actions.deleteResource', { resource: this.$tc('employer.title', 1) }),
            });
          } catch (error) {
            eventBus.$emit('snackbar', {
              color: 'error',
              text: this.$t('errors.generic'),
            });
            throw error;
          }
        },
      });
    },
  },
};
</script>
